@tailwind base;
@tailwind components;
@tailwind utilities;

.headtwo {
    line-height: 1.2 !important;
}

.loginbtn {
    margin: 0 !important;
}

/* .text-change:hover div p,div h5 {
    color:#fff !important;
} */

.underlined {
    position: relative;
    margin-right: 1rem;
}

.underlined:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 7px;
    width: 100%;
    border: solid 2px #0096FF;
    border-color: #0096FF transparent transparent transparent;
    border-radius: 30%;
}

svg#freepik_stories-finance:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-finance.animated #freepik--background-complete--inject-80 {
    animation: 1.5s Infinite linear floating;
    animation-delay: 0s;
}

svg#freepik_stories-finance.animated #freepik--money-box--inject-80 {
    animation: 1.5s Infinite linear floating;
    animation-delay: 0.5s;
}

@keyframes floating {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}